<template>
    <div>
        <fieldset class="fieldset">
            <legend>Généralités</legend>

              <vue-bootstrap-typeahead
                  v-model="search"
                  :data="searchData"
                  :minMatchingChars="2"
                  :serializer="s => s.forSuggestion"
                  placeholder="par nom, prénom, n° de licence"
                  @hit="search_hit"
                  prepend="Rechercher"
              >
              </vue-bootstrap-typeahead>

              <br/>

            <b-form-group label="Prénom"><b-input type="text" :disabled="id!=null && !userHasOneOfRoles([Roles.Admin])" v-model="firstname" @change="firstname_change" required/></b-form-group>
            <b-form-group label="Nom"><b-input type="text" :disabled="id!=null && !userHasOneOfRoles([Roles.Admin])" v-model="lastname" @change="lastname_change" required/></b-form-group>
            <b-form-group label="N° de licence FFVL"><b-input type="text" :disabled="id!=null && !userHasOneOfRoles([Roles.Admin])" v-model = "federalNumber" @change="federalNumber_change" /></b-form-group>
            <b-form-group label="Sexe">
                <b-radio-group v-model="sexe" :disabled="id!=null && !userHasOneOfRoles([Roles.Admin])" @change="sexe_change">
                    <b-radio value="M">Homme</b-radio>
                    <b-radio value="F">Femme</b-radio>
                </b-radio-group>
            </b-form-group>
            <b-form-group v-if="false" label="Date de naissance"><b-datepicker v-model="birthdate" :disabled="id!=null && !userHasOneOfRoles([Roles.Admin])" @change="birthdate_change"></b-datepicker></b-form-group>
            <b-button v-if="id==null" @click="saveChanges">{{ (id == null) ? "Enregistrer le nouveau membre" : "Enregistrer les modifications"}}</b-button>
        </fieldset>
        <div v-if="id!=null">
          <fieldset  class="fieldset" v-for="competitionType in competitionsTypes" :key="competitionType.code">
              <legend>{{ competitionType.name}}</legend>
              <staff-member-capabilities
                :showCompetitionType="false"
                :items="getCapabilities(competitionType)"
                :canEdit="userHasOneOfRoles([Roles.Admin])"
                @roleItem_change="role_change"
              >
              </staff-member-capabilities>
          </fieldset>
        </div>

    </div>
</template>

<script>
import { STAFF, REF_COMPETITION_TYPE, REF_SCOPE, REF_COMPETITION_TYPE_SCOPE, STAFF_CAPABILITY, PEOPLE } from "@/LocalDB";
import StaffEditionHelper from "@/services/helpers/staffEditionHelper";
import StaffMemberCapabilities from "@/components/StaffMemberCapabilities";
import VueBootstrapTypeahead from "vue-bootstrap-typeahead";

export default {
  components: { StaffMemberCapabilities, VueBootstrapTypeahead },
    data(){
        return{
            id: null,
            creId: null,
            creFirstname: '',
            creLastname: '',
            creFederalNumber: '',
            creSexe: '',
            creBirthdate: '',
            StaffMember: null,
            search: '',
        }
    },
    computed:{
        competitionsTypes(){
            return REF_COMPETITION_TYPE.query().get()
        },
        scopes(){
            return REF_SCOPE.query().get();
        },

        firstname: {
          get(){ return (this.id == null) ? this.creFirstname : this.StaffMember.PEOPLE.firstname; },
          set(val){ this.creFirstname = val; }
        },

        lastname: {
          get(){ return (this.id == null) ? this.creLastname : this.StaffMember.PEOPLE.lastname },
          set(val){ this.creLastname = val}
        },

        federalNumber: {
          get(){ return (this.id == null) ? this.creFederalNumber : this.StaffMember.PEOPLE.federalNumber },
          set(val){this.creFederalNumber = val}
        },

        sexe:{
          get(){ return (this.id == null) ? this.creSexe : this.StaffMember.PEOPLE.sexe },
          set(val){ this.creSexe = val;}
        },

        birthdate: {
          get(){ return (this.id == null) ? this.creBirthdate : this.StaffMember.PEOPLE.birthdate },
          set(val){ this.creBirthdate = val; }
        },
        searchData(){
          return PEOPLE.all()
                  .filter(item => !STAFF.query().where('id', item.id).exists())
                  .map(item => {
                    item.forSuggestion = item.firstname + ' ' + item.lastname + ' - ' + item.federalNumber;
                    return item;
                  })
                  .sort((a,b) => (a.forSuggestion < b.forSuggestion) ? -1 : ((a.forSuggestion > b.forSuggestion) ? 1 : 0));
        }
    },
    methods:{
        getCapabilities(competitionType)
        {
            return REF_COMPETITION_TYPE_SCOPE.query().where('competition_type', competitionType.code).get().map(scopePossible => {
              var item = STAFF_CAPABILITY.query().where("staff_id", this.id).where("competition_type", competitionType.code).where("scope", scopePossible.scope).first();
              if(item == null)
                item = {
                  staff_id: this.id,
                  competition_type: competitionType.code,
                  scope: scopePossible.scope,
                  isJudge: false,
                  isJudgeShadow: false,
                  isTechJudge: false,
                  isTechJudgeShadow: false,
                  isArtJudge: false,
                  isArtJudgeShadow: false,
                  isFieldDirector: false,
                  isFieldDirectorShadow: false,
                  isScorer: false,
                  isScorerShadow: false,
                  isTeacher: false,
                  isTeacherShawdow: false,
                  level: null,
                }
              return item;
            });
        },

        firstname_change(val){
            if(this.id == null)
              this.creFirstname = val;
            else
              this.$showRefresher()
                .then(() => {
                  StaffEditionHelper.changeFirstname(this.id, val);
                  this.$hideRefresher();
                })
        },

        lastname_change(val){
          if(this.id == null)
            this.creLastname = val;
          else
            this.$showRefresher()
              .then(() => {
                StaffEditionHelper.changeLastname(this.id, val);
                this.$hideRefresher();
              })
        },

        federalNumber_change(val){
          if(this.id == null)
            this.creFederalNumber = val;
          else
            this.$showRefresher()
              .then(() => {
                StaffEditionHelper.changeFederalNumber(this.id, val);
                this.$hideRefresher();
              })
        },

        sexe_change(val){
          if(this.id == null)
            this.creSexe = val;
          else
            this.$showRefresher()
              .then(() => {
                StaffEditionHelper.changeSexe(this.id, val);
                this.$hideRefresher();
              })
        },

        birthdate_change(val){
          if(this.id == null)
            this.creBirthdate = val;
          else
            this.$showRefresher()
              .then(() => {
                StaffEditionHelper.changeBirthdate(this.id, val);
                this.$hideRefresher();
              })
        },

        role_change(eventArgs)
        {
          this.$showRefresher()
            .then(()=>{
              StaffEditionHelper.setRole(this.id, eventArgs.competition_type, eventArgs.scope, eventArgs.roleName, eventArgs.newValue);
              this.$hideRefresher();
            })
        },

        search_hit(val){
          this.creId = val.id;
          this.creFirstname = val.firstname;
          this.creLastname = val.lastname;
          this.creFederalNumber = val.federalNumber;
          this.creSexe = val.sexe;
        },
        saveChanges(){
          this.$showRefresher()
            .then(() => {
                var newMember = null;
                if(this.creId == null)
                {
                    newMember = StaffEditionHelper.createStaffMember(this.creFirstname, this.creLastname, this.creFederalNumber, this.creSexe);
                } else {
                    newMember = StaffEditionHelper.setPeopleAsStaffMember(this.creId);
                    if(newMember != null)
                    {
                        if(this.creFirstname != newMember.PEOPLE.firstname)
                          StaffEditionHelper.changeFirstname(newMember.id, this.creFirstname);
                        if(this.creLastname != newMember.PEOPLE.lastname)
                          StaffEditionHelper.changeLastname(newMember.id, this.creLastname);
                        if(this.crefederalNumber != newMember.PEOPLE.federalNumber)
                          StaffEditionHelper.changeFederalNumber(newMember.id, this.creFederalNumber)
                        if(this.creSexe != newMember.PEOPLE.sexe)
                          StaffEditionHelper.changeSexe(newMember.id, this.creSexe);
                        if(this.creBirthdate != newMember.PEOPLE.birthDate)
                          StaffEditionHelper.changeBirthdate(newMember.id, this.creBirthdate);
                    }
                }
                if(newMember != null){
                  this.id = newMember.id;
                  this.loadAndRefresh();
                }
                this.$hideRefresher();
            });
        },
        loadAndRefresh(){
          if(this.id != null)
          {
            this.StaffMember = STAFF.query().where("id", this.id).first();
          }
        }
    },
    mounted(){
      this.id = this.$route.params["id"];
      this.loadAndRefresh();
      this.$hideLoader();
    },
    watch: {
        $route(to, from) {
            if (to !== from) {
                console.log("Staff.vue > route id changed : ", to);
                this.id = to;
                this.loadAndRefresh();
            }
        },
    },
}
</script>
